<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('template', 'read')" type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportTemplate($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>{{ $t('export') }}</span>
			</b-button>
			<b-button v-if="permissionEnabled('template', 'create')" type="is-primary create" rounded @click="create($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('add') }}</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field label="Ordem" :label-position="label">
					<b-select :placeholder="$t('name')" @input="filterByOrder">
						<option selected value="1">{{ $t('name') }}</option>
						<option value="2">{{ $t('date') }}</option>
					</b-select>
				</b-field> 
				<b-field>
					<b-input :placeholder="$t('search')" type="search" icon-right="magnify" v-model="filter.name" @input="findByName"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="templates.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-4-tablet is-3-desktop is-2-widescreen">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="t in templates" :key="t.id" class="list-item column is-12-mobile is-4-tablet is-3-desktop is-2-widescreen">
				<article class="block image no-cover" @click.self="update(t.id)">
					<div class="block__cover">
						<img :src="`${getUrlServer()}/assets/images/type-${imageVariation(t)}.png`" :alt="t.name">
					</div>
					<div class="block__content">
						<h3 class="block__name" translate="no" :title="t.name">{{ t.name }}</h3>
						<p class="block__email">{{ format(t.created_at) }} • {{ timeTo(t.created_at) }}</p>
					</div>
					<Trigger :id="t.id" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Trigger from '@/components/triggers/Template'
import Placeholder from '@/components/placeholders/Template'
import Api from '@/services/api'
import Modal from '@/components/modals/Template'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { responsive } from '@/mixins/responsive'
import { mapGetters, mapState } from 'vuex'
import { successToast, errorToast } from '@/mixins/toast'
import server from '@/mixins/server'

export default {
	name: 'Template',
	mixins: [
		responsive,
		server
	],
	components: {
		Layout,
		Header,
		Error,
		Results,
		Trigger,
		Placeholder,
		'svg-icon': Icon
	},
	data() {
		return {
			label: 'on-border',
			loading: true,
			errored: false,
			exporting: false,
			pagination: 12,
			templates: [],
			filter: {
				order: '',
				name: ''
			},
		}
	},
    mounted() {
        this.findAll()

		eventHub.$on('edit-modal-templates', obj => {
			update(this, 'templates', obj.id, Modal, 'Edit', obj.root)
		})
		eventHub.$on('delete-templates', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('attention'),
				message: '<span>' + this.$t('alert_delete') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('no'),
				confirmText: this.$t('yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`template/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('deleted_success'))
							await this.findAll()
						}
					} catch (e) {
						console.log(e)
						errorToast(this.$t('deleted_error'))
					}
				}
			})
		})
		eventHub.$on('reload-templates', () => {
			this.findAll()
		})
    },
	methods: {
        findAll() {
            Api.get('template/findAll')
                .then(({ data }) => {
                    this.templates = data
                })
                .catch((err) => {
                    console.log(err)
                    errorToast('Ocorreu um erro ao carregar os templates')
                })
				.finally(() => {
					this.loading = false
				})
        },
		async filterByOrder(e) {
			try {
				this.loading = true
				this.filter.order = e
				const response = await Api.post('template/filterByOrder', {
					name: this.filter.order
				})

				if (response.status === 200) {
					this.templates = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			try {
				this.loading = true
				const empty = /^\s*$/
				if (!empty.test(this.filter.name)) {
					const response = await Api.post('template/findByName', {
						name: this.filter.name
					})
					if (response.status === 200) {
						this.templates = response.data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		update(id) {
			if (this.permissionEnabled('template', 'edit')) {
				update(this, 'templates', id, Modal, 'Edit')
			}
		},
		create() {
			if (this.permissionEnabled('template', 'create')) {
				create(this, 'templates', Modal, 'New')
			}
		},
		async exportTemplate() {
			this.exporting = true
			try {
				const response = await Api.post('template/export')
				const { status } = response
				if (![200, 201].includes(status)) {
					errorToast(this.$t('exported_error'))
				} else {
					const { message } = response.data
					successToast(this.$t('exported_success'))
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		},
		imageVariation (template) {
			let v = template.variation,
				t = template.type
			
			return v > 1 ? `${t}-${v}` : t
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
		...mapState('user', ['user'])
	},
	destroyed() {
		eventHub.$off('edit-modal-templates')
		eventHub.$off('delete-templates')
		eventHub.$off('reload-templates')
	}
}
</script>
