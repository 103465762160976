<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(save)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $t('template') }}</strong></span>
					<span v-if="template.id">#{{ template.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card templates mb-3">
					<InputWithValidation class="mb-4" name="name" rules="required|min:3" type="text" :label="$t('name')" v-model="template.name" />
					
					<b-field class="mb-4" :label="$t('type')">
						<multiselect 
							v-model="template.type" 
							tag-position="top"
							label="name" 
							track-by="name" 
							:max-height="148" 
							:options="types"
							:close-on-select="true" 
							:hide-selected="true"
							:searchable="false"
							:placeholder="template.type ? template.type.name  : ''"
							selectLabel="" 
							selectedLabel="" 
							deselectLabel="">
							<span slot="noResult">Nenhum tipo encontrado!</span>
							<template class="types" slot="option" slot-scope="props">
								<img class="option__image" :src="`${getUrlServer()}/assets/images/type-${props.option.id}.png`">
								<span class="option__name">{{ props.option.name }}</span>
							</template>
						</multiselect>
					</b-field>
					
					<SelectWithValidation v-if="template.type && template.type.id == 2" class="mb-4" rules="required" label="Qual a posição da imagem?" v-model="template.variation">
						<option v-for="v in variationsImage" :value="v.id" :key="v.id">{{ v.name }}</option>
					</SelectWithValidation>

					<SelectWithValidation v-if="template.type && template.type.id == 2 && template.variation && template.variation == 3" class="mb-4" rules="required" label="Qual o estilo do texto?" v-model="template.variation2">
						<option v-for="v in variationsTitleTextImage" :value="v.id" :key="v.id">{{ v.name }}</option>
					</SelectWithValidation>

					<SelectWithValidation v-if="template.type && template.type.id == 5" class="mb-4" rules="required" label="Qual a periodicidade dos eventos?" v-model="template.variation">
						<option v-for="v in variationsIcs" :value="v.id" :key="v.id">{{ v.name }}</option>
					</SelectWithValidation>

					<SelectWithValidation v-if="template.type && template.type.id == 9" class="mb-4" rules="required" label="Quantas colunas na lista?" v-model="template.variation">
						<option v-for="v in variationsList" :value="v.id" :key="v.id">{{ v.name }}</option>
					</SelectWithValidation>

					<!-- 
					<b-upload class="button is-secondary" v-model="templateImage" accept="image/png, image/jpeg, image/gif">
						Selecione a imagem
					</b-upload>
					-->

					<small class="modal-updated" v-if="template.updated_at">{{ $t('last_change') }} {{ format(template.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'
import { templateTypes } from '@/services/constants'
import server from '@/mixins/server'

export default {
	mixins: [server],
	components: {
		InputWithValidation,
		SelectWithValidation,
		Multiselect,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			template: {},
			templateImage: null,
			preview: null,
			role: 1,
			permission: [],
			visible: false,
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			types: templateTypes,
			variationsImage: [
				{ id: 1, name: 'Direita' },
				{ id: 2, name: 'Esquerda' },
				{ id: 3, name: 'Fundo' },
			],
			variationsIcs: [
				{ id: 1, name: 'Dia' },
				{ id: 2, name: 'Semana' },
				{ id: 3, name: 'Mês' },
			],
			variationsList: [
				{ id: 1, name: '1 Coluna' },
				{ id: 2, name: '2 Colunas' },
			],
			variationsTitleTextImage: [
				{ id: 1, name: 'Claro' },
				{ id: 2, name: 'Escuro' },
			],
		}
	},
	methods: {
		async update() {
			try {
				this.loading = true
				let formData = new FormData()
				formData.append('_method', 'put')
				formData.append('name', this.template.name)
				formData.append('type', this.template.type.id)

				if (this.template.variation) {
					formData.append('variation', this.template.variation)
				}

				if (this.template.variation2) {
					formData.append('variation2', this.template.variation2)
				}

				// if (this.templateImage) {
				// 	formData.append('image', this.templateImage)
				// }

				const response = await Api.post(`template/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if (status === 201) {
					this.$emit('close')
					history.pushState({}, '', '/templates')
					successToast(this.$t('saved_success'))
					eventHub.$emit('reload-templates')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('saved_error'))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`template/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.template = data
						this.template.type = templateTypes.find(t => t.id == data.type)
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()
				formData.append('name', this.template.name)
				formData.append('type', this.template.type.id)

				if (this.template.variation) {
					formData.append('variation', this.template.variation)
				}

				if (this.template.variation2) {
					formData.append('variation2', this.template.variation2)
				}

				// if (this.templateImage) {
				// 	formData.append('image', this.templateImage)
				// }

				const response = await Api.post('template/store', formData, this.configRequest)
				const { status } = response
				if (status === 201 || status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/templates')
					successToast(this.$t('saved_success'))
					eventHub.$emit('reload-templates')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('saved_error') + '! <small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		async save() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async updateImage (e) { 
			const file = e.target.files[0]
			console.log(file)
			this.preview = await this.readFile(file)
			this.templateImage = file
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
